<template>
  <li>
    <div class="card">
      <div class="card-media h-32">
        <div class="card-media-overly"></div>
        <img :src="data.images" alt="" class="" v-if="styles == 'group'">
        <img :src="'https://fbeubaya.id/assets/img/events/' + data.image" alt="" v-if="styles == 'events'">
        <div class="absolute bg-red-100 font-semibold px-2.5 py-1 rounded-lg text-red-500 text-xs top-2.5 left-2.5" v-if="data.tag != '' && data.tag != null && styles != 'group'">{{ data.tag }}</div>
        <div class="tags" v-if="data.privacy">
          <div class="absolute privacy-label">
            {{ data.privacy }}
          </div>
        </div>
      </div>
      <div class="card-body" v-if="styles == 'group'">
          <router-link :to="{ name: 'GroupDetail', params: {code: data.slug } }" class="font-semibold text-lg truncate" v-b-tooltip.hover :title="data.title">{{ data.title }}</router-link>
          <div class="flex items-center flex-wrap space-x-1 text-sm text-gray-500 capitalize">
            <a href="#"> <span>{{ data.total_member }}</span> </a>
            <a href="#"> <span>{{ data.total_post }}</span> </a>
          </div>
          <div class="flex mt-2 space-x-2">
            <div class="flex items-center -space-x-2 -mt-1">
              <img alt="Image placeholder" v-for="member in data.member.slice(0, 2)" :key="member.id" :src="member.user.images ? member.user.images : $placeholder" class="border-2 border-white rounded-full w-7 h-7">
            </div>
            <div class="flex-1 leading-4 text-sm">
              <div v-html="data.text_member"></div>
            </div>
          </div>
          <div class="flex mt-3 space-x-2 text-sm">
            <button @click="actionJoin" class="bg-blue-600 flex flex-1 h-8 items-center justify-center rounded-md text-white capitalize" v-if="!data.user_id && data.waiting_approval !== 1 && from != 'frontpage'">Join</button>
            <router-link to="/login" class="bg-blue-600 flex flex-1 h-8 items-center justify-center rounded-md text-white capitalize" v-if="from == 'frontpage'">Join</router-link>
            <button class="bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize" v-if="data.waiting_approval === 1">Waiting Approval</button>
            <button class="bg-danger text-white px-2 h-8 items-center justify-center rounded-md capitalize" @click="leaveGroup" v-if="data.waiting_approval === 1">Cancel</button>
            <router-link :to="{ name: 'GroupDetail', params: {code: data.slug } }" class="bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize" v-if="data.user_id">View</router-link>
          </div>
      </div>
      <div class="card-body" v-if="styles == 'events'">
        <router-link :to="{ name: 'DetailEvent', params: {code: data.nomor } }" class="box-title mb-1 truncate" v-b-tooltip.hover :title="data.title">{{ data.title }}</router-link>
        <div class="flex items-center space-x-2 mb-4 text-xs text-gray-500 capitalize">
            <div>{{ data.date_format }}</div>
            <div>·</div>
            <div>{{ data.location }}</div>
        </div>
        <div class="flex mt-2 space-x-2 text-sm">
            <button class="btn btn-primary disabled" v-if="data.expired === '1'">
                Expired
            </button>
            <router-link :to="{ name: 'DetailEvent', params: {code: data.nomor } }" class="btn btn-primary" v-else>
                View
            </router-link>
            <a :href="'https://fbeubaya.id/events/register/' + data.nomor" target="_blank" class="btn btn-warning" v-if="data.expired !== '1' && data.show_button === '1'">
                Register
            </a>
        </div>
        </div>
    </div>
  </li>
</template>
<script>
    import axios from 'axios'

    export default {
        name: 'card_blog',
        props: ['data', 'styles', 'from'],
        methods: {
            actionJoin () {
                const $this = this
                axios.get('group/join/' + this.$props.data.slug).then(function (response) {
                    var titleAlert = 'Waiting Approval'
                    var imageVector = require('@/assets/images/admin-confirm.png')
                    if (response.data.verification === 1) {
                        titleAlert = 'Success Join Group'
                        imageVector = require('@/assets/images/vector-success.png')
                    }
                    $this.$swal({
                        imageUrl: imageVector,
                        imageWidth: 400,
                        title: titleAlert,
                        text: response.data.message
                    }).then(function () {
                        if (response.data.verification === 1) {
                            $this.$router.push({ name: 'GroupDetail', params: { code: $this.$props.data.slug } })
                        } else {
                            $this.data.waiting_approval = 1
                        }
                    })
                }).catch(function () {
                    $this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                })
            },
            leaveGroup () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, cancel now!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.get('group/leave/' + this.$props.data.id)
                            .then(() => {
                                this.$swal(
                                    'Cancel!',
                                    'You have cancel join the group.',
                                    'success'
                                ).then(() => {
                                    this.data.waiting_approval = 0
                                })
                            })
                            .catch((e) => console.log(e))
                    }
                })
            }
        }
    }
</script>
