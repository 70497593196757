<template>
  <div id="events">
    <transition name="slide-fade" mode="out-in">
    <div class="mcontainer" v-if="this.$route.name === 'Events' ">
      <div class="flex justify-between relative md:mb-4 mb-3">
        <div class="flex-1">
          <h2 class="text-lg font-semibold"> Events </h2>
          <p>Acara-acara yang anda ikuti dan akan datang</p>
        </div>
        <!-- <router-link to="/group/create" class="flex items-center justify-center h-9 lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 absolute right-0">
          <Icon icon="uiw:user-add" />
          <span class="md:block hidden"> Create Event </span>
        </router-link> -->
      </div>
      <div class="relative">
        <div class="px-1 py-3">
          <b-tabs content-class="mt-4">
            <b-tab title="All Event" active>
                <transition name="fade" mode="out-in">
                    <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid" v-if="loading">
                        <CardBlog v-for="event in events" :key="event.nomor" :data="event" :styles="'events'" />
                    </ul>
                    <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid" v-if="!loading">
                        <content-placeholders v-for="index in 4" :key="index" class="my-3">
                        <content-placeholders-img />
                        <content-placeholders-heading />
                        </content-placeholders>
                    </ul>
                </transition>
                <div v-if="loading_other" class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid">
                    <content-placeholders v-for="index in 1" :key="index" class="my-3">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                    </content-placeholders>
                </div>
                <div class="flex justify-center mt-6" v-if="loadmore_all_show">
                    <a href="javascript:void(0)" @click="loadmore_all()" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                        Load more</a>
                </div>
                <p v-else>No data can be displayed</p>
            </b-tab>
            <b-tab title="Upcoming Event">
                <transition name="fade" mode="out-in">
                    <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid" v-if="loading">
                        <CardBlog v-for="up in upcoming" :key="up.nomor" :data="up" :styles="'events'" />
                    </ul>
                    <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid" v-if="!loading">
                        <content-placeholders v-for="index in 4" :key="index" class="my-3">
                        <content-placeholders-img />
                        <content-placeholders-heading />
                        </content-placeholders>
                    </ul>
                </transition>
                <div v-if="loading_other" class="uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid">
                    <content-placeholders v-for="index in 1" :key="index" class="my-3">
                    <content-placeholders-img />
                    <content-placeholders-heading />
                    </content-placeholders>
                </div>
                <div class="flex justify-center mt-6" v-if="loadmore_upcoming_show">
                    <a href="javascript:void(0)" @click="loadmore_upcoming()" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                        Load more</a>
                </div>
                <p v-else>No data can be displayed</p>
            </b-tab>
            <!-- <b-tab title="Joined">Joined Content</b-tab> -->
          </b-tabs>
        </div>
      </div>
    </div>
    <router-view/>
    </transition>
  </div>
</template>
<script>
    import CardBlog from '@/components/CardBlog'
    import axios from 'axios'

    export default {
        name: 'Events',
        components: {
            CardBlog
        },
        data () {
            return {
                events: [],
                upcoming: [],
                loading: false,
                loading_other: false,
                loadmore_all_show: true,
                loadmore_upcoming_show: true,
                page: 1,
                page_upcoming: 1
            }
        },
        created () {
            this.fetchData()
        },
        methods: {
            fetchData () {
                const $this = this
                axios.get('https://fbeubaya.id/api/get_events_all/' + this.page).then(function (response) {
                    $this.events.push(...response.data)
                    $this.loading = true
                    if (response.data.length === 0) {
                        $this.loadmore_all_show = false
                    }
                })
                axios.get('https://fbeubaya.id/api/get_events_upcoming/' + this.page_upcoming).then(function (response) {
                    $this.upcoming.push(...response.data)
                    $this.loading = true
                    if (response.data.length === 0) {
                        $this.loadmore_upcoming_show = false
                    }
                })
            },
            loadmore_all () {
                this.loading_other = true
                const $this = this
                this.page = this.page + 1
                axios.get('https://fbeubaya.id/api/get_events_all/' + this.page).then(function (response) {
                    $this.events.push(...response.data)
                    $this.loading_other = false
                    if (response.data.length === 0) {
                        $this.loadmore_all_show = false
                    }
                })
            },
            loadmore_upcoming () {
                this.loading_other = true
                const $this = this
                this.page_upcoming = this.page_upcoming + 1
                axios.get('https://fbeubaya.id/api/get_events_all/' + this.page_upcoming).then(function (response) {
                    $this.upcoming.push(...response.data)
                    $this.loading_other = false
                    if (response.data.length === 0) {
                        $this.loadmore_upcoming_show = false
                    }
                })
            }
        }
    }
</script>
